'use strict'

// DATA TABLES PLUGIN
export default (cutoff, wordbreak, escapeHtml) => {
  const esc = (t) => {
    return t
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
  }

  return (d, type) => {
    // Order, search and type get the original data
    if (type !== 'display') {
      return d
    }
    if (typeof d !== 'number' && typeof d !== 'string') {
      return d
    }
    d = d.toString() // cast numbers
    if (d.length <= cutoff) {
      return d
    }
    let shortened = d.substr(0, cutoff - 1)
    // Find the last white space character in the string
    if (wordbreak) {
      shortened = shortened.replace(/\s([^\s]*)$/, '')
    }
    // Protect against uncontrolled HTML input
    if (escapeHtml) {
      shortened = esc(shortened)
    }
    return `<span class="ellipsis" title="${esc(d)}">${shortened}&#8230;</span>`
  }
}